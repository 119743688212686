document.addEventListener("turbolinks:load", () => {
  // Paramètres d'URL
  let href = window.location.href;

  if (href.indexOf('?a=') > 0) {
    let url = href.split('?a=');
    history.pushState(null, null, url[0]);
  } else if (href.indexOf('&a=') > 0) {
    let url = href.split('&a=');
    history.pushState(null, null, url[0]);
  }

  // Background images
  $('.bg_image_holder').each(function () {
    let $this = $(this);
    let imgLink = $this.children().attr('src');
    $this.css({
      "background-image": "url(" + imgLink + ")",
      "opacity": "1"
    }).children().attr('alt', imgLink);
  });

  // Top menu mobile
  let opened = false;

  $('.menu_icon').on('click', function () {
    $(this).siblings('.offcanvas-menu').removeClass('closed');
    opened = true;
  });

  const $menu = $('.mobile_content');

  $(document).mouseup(e => {
    if (!$menu.is(e.target) && $menu.has(e.target).length === 0 && opened) {
      $('.menu_icon').siblings('.offcanvas-menu').addClass('closed');
    }
  });

  // Affiliate find link page
  function copyToClipboard(element) {
    let $temp = $("<input>");
    $("body").append($temp);
    $temp.val($(element).text()).select();
    document.execCommand("copy");
    $temp.remove();
    toastr["success"]("Le lien a été copié !", '', {timeOut: 1000});
  }

  $(".affiliate_link").each(function(){
    let affiliate_link_text_element = $(this).find('.affiliate_link_text');
    $(this).find(".copy_button").on('click', function() {
      copyToClipboard(affiliate_link_text_element);
    });
  });

  // Affichage des vidéos YouTube ou Vimeo

  function youtube_id(video_url) {
    let regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    let match = video_url.match(regExp);

    if (match && match[2].length === 11) {
      return match[2];
    } else {
      return 'error';
    }
  }

  function vimeo_id(video_url) {
    let regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
    let match = video_url.match(regExp);

    if (match){
      return match[2];
    }
    else {
      return 'error';
    }
  }

  function youtube_thumbnail(video_url) {
    return "https://img.youtube.com/vi/"+ youtube_id(video_url) + "/mqdefault.jpg";
  }

  function vimeo_thumbnail(video_url) {
    let promise = new Promise(function(resolve, reject) {
      $.getJSON('http://www.vimeo.com/api/v2/video/' + vimeo_id(video_url) + '.json?callback=?', { format: "json" }, (data) => {
        console.log('yes');
        console.log(data[0].thumbnail_large);
        resolve({img_src: data[0].thumbnail_large});
      });
    });

    return promise;
  }

  let youtube_videos = [];

  window.init_player = function init_player(element) {
    let video_url = element.data('url');
    let video_type = element.data('type');
    let container_id = element.data('id');

    if (video_url != null && video_url !== '') {
      if (video_type === 'youtube') {
        if (typeof YT !== 'undefined') {
          try {
            new YT.Player(container_id, {
              height: '360',
              width: '640',
              videoId: youtube_id(video_url),
            });
          } catch(err) {
            console.log(err);
            setTimeout(() => {
              init_player(element);
            }, 1000);
          }
        } else {
          let video = [container_id, video_url]
          youtube_videos.push(video);

          window.onYouTubeIframeAPIReady = function onYouTubeIframeAPIReady() {
            youtube_videos.forEach(function(item, index, array) {
              console.log(item, index);
              new YT.Player(item[0], {
                height: '360',
                width: '640',
                videoId: youtube_id(item[1]),
              });
            });
          };
        }
      } else {
        $('#' + container_id).attr('data-vimeo-url', video_url);
        if (typeof Vimeo !== 'undefined' && typeof Vimeo.Player !== 'undefined') {
          new Vimeo.Player(container_id)
        }
      }
    }
  }

  $(".video_player").each(function() {
    init_player($(this));
  });

  function init_thumbnail(element) {
    let video_url = element.data('url');
    let video_type = element.data('type');
    let alt = element.data('id');

    if (video_type === 'youtube') {
      element.html('<img src="'+ youtube_thumbnail(video_url) +'" alt="' + alt +'">');
    } else {
      vimeo_thumbnail(video_url).then((data) => {
        element.html('<img src="'+ data.img_src +'" alt="' + alt +'">');
      });
    }
  }

  $(".video_thumbnail").each(function() {
    init_thumbnail($(this))
  });

  // Commentaires

  let $replyForm = $('.reply-comment'),
      $replylink = $('.reply-link'),
      $subreplylink = $('.child.reply-link');

  $replyForm.hide();
  $replylink.on('click', function (e) {
    e.preventDefault();
    $(this).parents('.media').siblings('.reply-comment').show().find('textarea').focus();
  });
  $subreplylink.on('click', function(e) {
    console.log('sublink')
    e.preventDefault();
    $(this).parents('.children').siblings('.reply-comment').show().find('textarea').focus();
  });
});


(function() {
  if (typeof Turbolinks === "undefined" || Turbolinks === null) {
    location.reload;
  }

  Turbolinks.dispatch("turbolinks:load");
}).call(this);