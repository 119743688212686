import consumer from "./consumer"

consumer.subscriptions.create("ChatroomsChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    // Dans tous les cas, on affiche le message dans la conversation et on  met à jour l'affichage de la liste des
    // conversations sur le côté. Si l'utilisateur est sur la bonne page.

    // AFFICHAGE DU MESSAGE DANS LA SIDEBAR

    let body_preview;
    if (data.has_attachments) {
      body_preview = '<i class="fas fa-paperclip"></i> ' + data.body.substring(0, 21);
    } else {
      body_preview = data.body.substring(0, 21) + (data.body.length > 20 ? "..." : "");
    }

    // Si l'utilisateur est bien sur l'une de ses conversations
    let my_chats = $("#my_chats");
    if (my_chats.length > 0) {
      let chatroom_message_preview = $("[data-behavior='chatroom-message-preview'][data-chatroom-id='" + data.chatroom_id + "']");

      // Si l'aperçu de la conversation existait déjà avant
      if (chatroom_message_preview.length > 0) {
        chatroom_message_preview.html(body_preview);
        let sidebar_link = $("#chatroom_link_" + data.chatroom_id);
        $("#chatroom_link_" + data.chatroom_id + " .message .message_data .time").html("À l'instant");
        let content = sidebar_link[0].outerHTML;
        sidebar_link.remove();
        my_chats.prepend(content);
      } else { // Sinon
        my_chats.prepend(`
                  <a href="/conversations/${data.chatroom_id}">
                    <div class="message" data-behavior="chatroom-card" data-chatroom-id="${data.chatroom_id}">
                      <div class="message_data">
                        <div class="name_time">
                          <div class="name">
                            <p>
                                ${data.recipients}
                            </p>
                          </div>

                          <span class="time">À l'instant</span>
                          <p data-behavior="chatroom-message-preview" data-chatroom-id="${data.chatroom_id}">
                              ${body_preview}
                          </p>
                        </div>
                      </div>
                    </div>
                  </a>`);
      }
    }

    // AFFICHAGE DU MESSAGE DANS LA CONVERSATION

    let active_chatroom = $("[data-behavior='messages'][data-chatroom-id='" + data.chatroom_id + "']");
    // Si l'utilisateur est bien sur la conversation du message en question
    if (active_chatroom.length > 0) {
      active_chatroom.append(data.message);
    }

    // AFFICHAGE DES ÉLÉMENTS DE NOTIFICATION

    // Si c'est un receveur, on ajoute la notification en haut
    if (data.type === "receiver") {
      // Notification dans le menu du haut
      $("#no_message").hide();
      let notification = $("[data-behavior='chatroom-message-notification'][data-chatroom-id='" + data.chatroom_id + "']");
      if (notification.length > 0) {
        notification.html(body_preview);
        let notification_link = $("#chatroom_notification_link_" + data.chatroom_id);
        $("#chatroom_notification_link_" + data.chatroom_id + " .message_data .name_time .time").html("À l'instant");
        let content = notification_link[0].outerHTML;
        notification_link.remove();
        $("#messages_notifications").prepend(content);
      } else {
        $("#messages_notifications").prepend(`
                  <a class="message recent ${data.type === "receiver" ? "background-blue-notification" : "" }" id="chatroom_notification_link_${data.chatroom_id}" href="/conversations/${data.chatroom_id}">
                    <div class="message_data">
                      <div class="name_time">
                        <div class="name">
                           <p>
                            ${data.sender_public_name}
                           </p>
                        </div>

                        <span class="time">À l'instant</span>
                        <p data-behavior="chatroom-message-notification" data-chatroom-id="${data.chatroom_id}">
                            ${body_preview}
                        </p>
                      </div>
                    </div>
                  </a> `);
      }

      if (notification.length > 0) {
        let notification_link = $("#chatroom_notification_link_" + data.chatroom_id);
        notification_link.addClass("background-blue-notification");
      }

      if (active_chatroom.length > 0) {
        // Permet sur le script chatrooms.js, d'exécuter la requête ajax qui indique que l'utilisateur a lu les messages
        $("[data-behavior='messages']").removeClass("all_read");
      }

      // Mise à jour du pins de notification
      if (!data.already_notified) {
        let notification_count = $("#messages_notification_count");
        if (notification_count.length > 0) {
          notification_count.html(parseInt(notification_count.html()) + 1);
        } else {
          $("#messages_notification_icon").append("<span class=\"notification_count msg\" id=\"messages_notification_count\">1</span>")
        }
      }

      // Message en bleu dans la sidebar
      let sidebar_link = $("[data-behavior='chatroom-card'][data-chatroom-id='" + data.chatroom_id + "']");
      if (sidebar_link.length > 0) {
        sidebar_link.addClass('active');
      }
    }
  }

});