// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("jquery")
require("@rails/activestorage").start()
require("channels")

require("toastr/toastr.scss")
import toastr from 'toastr';

toastr.options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-top-right",
  "preventDuplicates": true,
  "onclick": null,
  "showDuration": "4000",
  "hideDuration": "1000",
  "timeOut": "5000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
};

global.toastr = toastr;

require('quill/core/quill.js')
require('quill/quill.js')
require('quill/dist/quill.snow.css')

global.Quill = Quill;

const I18n = require("i18n-js");
global.I18n = I18n;

require('theme/popper.min')
require('theme/bootstrap.min')

require('packs/main.js')
require('packs/translations.js')

// require('@uppy/core/dist/style.css')
// require('@uppy/dashboard/dist/style.css')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
