I18n.translations.en = {
  paypal: {
    locale: 'en_US'
  },
  courses: {
    content_form: {
      available_courses: 'Available courses',
      courses_in_pack: 'Courses in this pack',
      add_a_chapter: 'Add a chapter',
      add_a_lesson: 'Add a lesson',
      chapter_name: 'Chapter name',
      chapter_name_placeholder: 'Chapter 1: Course overview',
      lesson_name: 'Lesson name',
      add: 'Add',
      cancel: 'Cancel',
      move: 'Move',
      edit: 'Edit',
      delete: 'Delete',
      save: 'Save',
      quit: 'Quit without saving',
      delete_chapter_confirmation_question: 'Are you sure you want to delete this chapter?',
      delete_chapter_confirmation_info: 'All lessons in the chapter will also be deleted. This action is irreversible.',
      yes_delete: 'Yes, delete',
      name: 'Name',
      video_source: 'Video source',
      no_video: 'No video',
      video_url: 'Video url',
      video_not_found: 'Video not found',
      content: 'Content',
      loading_video: 'Loading video',
      delete_lesson_confirmation_question: 'Are you sure you want to delete this lesson?',
      delete_lesson_confirmation_info: 'All lesson content will be deleted. This action is irreversible.'
    },
    sequence_form: {
      add_a_page: 'Add a page',
      private_name_and_info: 'Private name of the page (only visible to you)',
      see_the_page: 'See the page',
      private_name: 'Private name',
      page_title: 'Page title',
      display_presentation_button: 'Display the button to the course presentation',
      delete_confirmation_question: 'Are you sure you want to delete this sequence page?',
      delete_confirmation_info: 'All content on the page will be deleted. This action is irreversible.'
    }
  },
  dates: {
    days: {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday'
    },
    months: {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December'
    }
  }
};

I18n.translations.fr = {
  paypal: {
    locale: 'fr_FR'
  },
  courses: {
    content_form: {
      available_courses: 'Formations disponibles',
      courses_in_pack: 'Formations dans ce pack',
      add_a_chapter: 'Ajouter un module',
      add_a_lesson: 'Ajouter une leçon',
      chapter_name: 'Nom du module',
      chapter_name_placeholder: 'Module 1 : Présentation de la formation',
      lesson_name: 'Nom de la leçon',
      add: 'Ajouter',
      cancel: 'Annuler',
      move: 'Déplacer',
      edit: 'Modifier',
      delete: 'Supprimer',
      save: 'Sauvegarder',
      quit: 'Quitter sans sauvegarder',
      delete_chapter_confirmation_question: 'Souhaitez-vous vraiment supprimer ce module ?',
      delete_chapter_confirmation_info: 'Toutes les leçons du module seront elles aussi supprimées. Cette action est irréversible.',
      yes_delete: 'Oui, supprimer',
      name: 'Nom',
      video_source: 'Source de la vidéo',
      no_video: 'Pas de vidéo',
      video_url: 'Url de la vidéo',
      video_not_found: 'Vidéo introuvable',
      content: 'Contenu',
      loading_video: 'Chargement de la vidéo',
      delete_lesson_confirmation_question: 'Souhaitez-vous vraiment supprimer cette leçon ?',
      delete_lesson_confirmation_info: 'Tout le contenu de la leçon sera supprimé. Cette action est irréversible.'
    },
    sequence_form: {
      add_a_page: 'Ajouter une page',
      private_name_and_info: 'Nom privé de la page (seulement visible par vous)',
      see_the_page: 'Voir la page',
      private_name: 'Nom privé',
      page_title: 'Titre de la page',
      display_presentation_button: 'Afficher le bouton vers la présentation de la formation',
      delete_confirmation_question: 'Souhaitez-vous vraiment supprimer cette page de séquence ?',
      delete_confirmation_info: 'Tout le contenu de la page sera supprimé. Cette action est irréversible.'
    }
  },
  dates: {
    days: {
      0: 'dimanche',
      1: 'lundi',
      2: 'mardi',
      3: 'mercredi',
      4: 'jeudi',
      5: 'vendredi',
      6: 'samedi'
    },
    months: {
      0: 'janvier',
      1: 'février',
      2: 'mars',
      3: 'avril',
      4: 'mai',
      5: 'juin',
      6: 'juillet',
      7: 'août',
      8: 'septembre',
      9: 'octobre',
      10: 'novembre',
      11: 'décembre'
    }
  }
};